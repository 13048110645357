import { MercuryTrackersKeys } from '@/constants/mercuryPages';

export const rfiKeys = {
  all: ['rfi'] as const,

  table: () => [...rfiKeys.all, 'table'] as const,

  meta: () => [...rfiKeys.all, 'meta'] as const,

  gfx: () => [...rfiKeys.all, 'gfx'] as const,

  issue: (issueId: number) => [...rfiKeys.all, 'issue', issueId],

  rfi: MercuryTrackersKeys.rfi,
};
