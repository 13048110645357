import type { TMercuryTrackersAccesor } from '@/constants/mercuryPages';
import { QueryKey } from '@tanstack/react-query';
import { incidentsKeys } from '../../incidentmanagement/services/keys';
import { intelligenceKeys } from '../../intelligence/services/keys';
import { rfiKeys } from '../../servicerequests/services/keys';

export const dashboardKeys: { [key in TMercuryTrackersAccesor]: () => QueryKey } & { all: QueryKey } = {
  all: ['dashboard'] as const,

  alerts: () => [...dashboardKeys.all, ...incidentsKeys.all, incidentsKeys.alerts],

  takedowns: () => [...dashboardKeys.all, ...incidentsKeys.all, incidentsKeys.takedowns],

  rfi: () => [...dashboardKeys.all, ...rfiKeys.all, rfiKeys.rfi],

  finished: () => [...dashboardKeys.all, ...intelligenceKeys.all, intelligenceKeys.finished],

  raw: () => [...dashboardKeys.all, ...intelligenceKeys.all, intelligenceKeys.raw],

  vulnerabilities: () => [...dashboardKeys.all, ...intelligenceKeys.all, intelligenceKeys.raw],
};
