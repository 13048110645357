import { Group, Text } from '@mantine/core';

import ChevronRight from '@/assets/icons/content/chevron-right.svg';
import ChevronLeft from '@/assets/icons/content/chevron-left.svg';

import classes from './Pagination.module.css';

interface EntriesPaginationProps {
  next: VoidFunction;
  previous: VoidFunction;
  onEntriesChange?: (size: number) => void;
  canNext: boolean;
  canPrevious: boolean;
  pageCount: number;
  maxPageCount?: number;
  size: number;
  page: number;
  hidePageSize?: boolean;
  entries?: Array<number>;
}

const EntriesPagination = ({
  next,
  previous,
  onEntriesChange,
  canNext,
  canPrevious,
  pageCount,
  maxPageCount = 1000,
  size,
  page,
  hidePageSize,
  entries,
}: EntriesPaginationProps) => {
  const defaultEntries = [10, 20, 30, 40, 50];
  const renderEntries = entries || defaultEntries;

  if (!pageCount) return null;

  return (
    <Group align="center" justify="space-between" mt={25}>
      <Group gap={10}>
        <Text size="sm" span>
          Page {page} of {pageCount >= maxPageCount ? 'Many' : pageCount}
        </Text>

        {!hidePageSize && onEntriesChange && (
          <select className={classes.entries} value={size} onChange={(e) => onEntriesChange(Number(e.target.value))}>
            {renderEntries.map((s) => (
              <Text component="option" size="sm" key={s} value={s}>
                {s}
              </Text>
            ))}
          </select>
        )}
      </Group>

      {pageCount > 1 && (
        <Group align="center" gap={15}>
          <button
            type="button"
            className={classes.paginationButton}
            aria-label="Previous Page"
            onClick={previous}
            disabled={!canPrevious}
          >
            <ChevronLeft />
          </button>
          <button
            type="button"
            className={classes.paginationButton}
            aria-label="Next Page"
            onClick={next}
            disabled={!canNext}
          >
            <ChevronRight />
          </button>
        </Group>
      )}
    </Group>
  );
};

export default EntriesPagination;
