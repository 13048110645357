import { forwardRef, ReactNode, Ref } from 'react';
import styled from 'styled-components';

interface IconButtonProps {
  icon: ReactNode;
  label: string;
  iconColor?: string;
  type?: 'button' | 'submit' | 'reset';
  onClick?: VoidFunction;
  disabled?: boolean;
}

const IconButtonStyled = styled.button<{ $iconColor?: string }>`
  display: flex;

  svg {
    fill: ${(props) => props.$iconColor && props.$iconColor};
  }

  &:disabled {
    opacity: 0.5;
  }

  &:hover {
    svg {
      fill: var(--quo-power);
    }
  }
`;
const IconButton = forwardRef(
  ({
    icon,
    type = 'button',
    label,
    iconColor,
    onClick,
    disabled,
  }: IconButtonProps, ref: Ref<HTMLButtonElement>) => (
    <IconButtonStyled
      ref={ref}
      className="icon-button"
      $iconColor={iconColor}
      type={type}
      onClick={onClick}
      disabled={disabled}
      aria-label={label}
    >
      {icon}
    </IconButtonStyled>

  ),
);

export default IconButton;
