import { Box, Loader, Stack, Text } from '@mantine/core';

interface LoadingProps {
  text?: string;
  height?: string;
}

const Loading = ({ text, height }: LoadingProps) => (
  <Box h={height}>
    <Stack gap={25} align="center" justify="center" h="100%">
      <Loader size={80} />

      {text && <Text size="24px">{text}</Text>}
    </Stack>
  </Box>
);

export default Loading;
