// Styles & Assets
import Moon from '@/assets/icons/content/moon.svg';

import { useTheme } from '@/hooks/useTheme';

const ThemeSwitch = () => {
  const { theme, changeTheme } = useTheme();
  const themeToSwitch = theme === 'light' ? 'dark' : 'light';

  const toggleTheme = () => {
    changeTheme(themeToSwitch);
  };

  return (
    <button type="button" aria-label={`Switch to ${themeToSwitch} mode`} onClick={toggleTheme} id="theme-switch">
      <Moon />
    </button>
  );
};

export default ThemeSwitch;
