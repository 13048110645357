import { isNotNull } from './general';

export const stringifyParams = (state: Record<string, any>) => Object.entries(state).reduce((accu, [key, val]) => {
  if (Array.isArray(val)) {
    val.forEach((v) => {
      accu.append(key, v);
    });
  } else if (isNotNull(val) && typeof val === 'object') {
    Object.entries(val).forEach(([deepKey, deepVal]: any) => {
      if (deepVal) {
        accu.append(`${key}[${deepKey}]`, deepVal);
      }
    });
  } else if (typeof val !== 'undefined' && String(val)) {
    accu.append(key, val);
  }

  return accu;
}, new URLSearchParams()).toString();
