/* eslint-disable jsx-a11y/label-has-associated-control */
import styled from 'styled-components';

// Components
import Button from '../../../button/Button';

// Misc
import { formatBytes } from '@/utils/number';

const FileListStyled = styled.ul`
  margin-top: 15px;
  margin-bottom: 15px;

  li {
    font-size: 12px;

    &:not(:first-child) {
      margin-top: 10px;
    }
  }
`;

const ClearButton = styled.button`
  color: var(--active-color);
  font-weight: bold;
  flex-shrink: 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 15px;

  @media (max-width: ${(props) => props.theme.breakpoints.SMax}) {
    button:first-child {
      min-width: auto;
      width: 100%;
    }
  }
`;

const FileInputSection = ({ value, onClear, name, extension }: any) => {
  const isSet = value && value.length > 0;
  return (
    <>
      <ButtonWrapper>
        <Button size="sm">
          <label htmlFor={name} style={{ cursor: 'pointer' }}>
            Choose file {extension && `(${extension})`}
          </label>

        </Button>

        {isSet && <ClearButton type="button" onClick={onClear}>Clear</ClearButton>}
      </ButtonWrapper>

      {
        isSet && (
          <FileListStyled>
            {[...(value as any[])].map((file) => (
              <li key={file.name}>- {file.name} - <b>{formatBytes(file.size)}</b></li>
            ))}
          </FileListStyled>
        )
      }
    </>
  );
};

export default FileInputSection;
