import { ScrollArea } from '@mantine/core';
import classes from './Topbar.module.css';

const HeaderDisclaimer = () => (
  <ScrollArea className={classes.disclaimer} scrollbarSize={4} h={35}>
    WARNING: NON-PRODUCTION ENVIRONMENT - MAY CONTAIN TESTING DATA AND EXPERIMENTAL FEATURES,
    DO NOT USE FOR PRODUCTION PURPOSES
  </ScrollArea>
);

export default HeaderDisclaimer;
