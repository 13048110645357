import { useCallback } from 'react';
import type { CoreInstance, Row as RowType } from '@tanstack/react-table';

// Components
import Row from './Row';

// Types
import type { ExtraDataObj } from '@/types/mercury-data-types';

interface BodyProps<TTableData> {
  instance: CoreInstance<TTableData>;
  subRowComponent?: ({ row }: { row: RowType<TTableData> }) => JSX.Element
  readonly extraData?: ExtraDataObj;
  noHighlight?: boolean;
}

const Body = <TTableData extends unknown>({ instance,
  noHighlight,
  extraData,
  subRowComponent: SubRowComponent }: BodyProps<TTableData>) => {
  const renderRowSubComponent = useCallback((row: RowType<TTableData>) => (
    SubRowComponent && <SubRowComponent row={row} />
  ), []);

  return (
    <tbody>
      {instance.getRowModel().rows.map((row) => (
        <Row
          noHighlight={noHighlight}
          key={row.id}
          row={row}
          isSelected={row.getIsSelected()}
          isExpanded={row.getIsExpanded()}
          extraData={extraData}
          renderRowSubComponent={renderRowSubComponent}
        />
      ))}
    </tbody>
  );
};

export default Body;
