import { useState } from 'react';
import useUrlState from './useUrlStore';
import {
  PaginationState, SortingState, ColumnFiltersState, GroupingState, ExpandedState,
} from '@tanstack/react-table';

interface TanStackState {
  pagination: PaginationState;
  sorting: SortingState;
  grouping: GroupingState;
  columnFilters?: ColumnFiltersState;
  expanded?: ExpandedState;
}

export interface TableState {
  start: number;
  length: number;
  order: string;
  grouping: Array<string>;
  'order-dir': string;
}

export const getTableInitialState = (mergeState?: Partial<TableState>) => ({
  start: 0,
  length: 10,
  ...(mergeState || {}),
});

const transformState = (tableParams: TableState): TanStackState => ({
  pagination: {
    pageIndex: tableParams.start / tableParams.length,
    pageSize: Number(tableParams.length),
  },
  ...(tableParams.grouping && tableParams.grouping.length ? { expanded: true } : {}),
  grouping: tableParams.grouping ? tableParams.grouping : [],
  sorting: tableParams.order ? [{ id: tableParams.order, desc: tableParams['order-dir'] === 'desc' }] : [],
});

export const useTableStore = (initialState: Partial<TableState> = {}, namespace?: string) => {
  const [
    state,
    actions,
  ] = useUrlState(
    getTableInitialState(initialState),
    { namespace, nest: ['table'] },
  );

  const { grouping: ignoreGrouping, ...tableUrlState } = state;

  const [rowSelection, setRowSelection] = useState({});

  const tableState = transformState(state as TableState);

  const dispatchPagination = (start: number, length: number) => {
    actions.set({ start, length });
  };

  const dispatchGrouping = (grouping: Array<string>) => {
    actions.set({ grouping });
  };

  const dispatchSorting = (orderBy: string, direction: string) => {
    actions.set({
      order: orderBy,
      'order-dir': direction,
    });
  };

  return {
    tableUrlState: tableUrlState as TableState,
    tableInstanceState: { ...tableState, rowSelection },
    dispatchPagination,
    dispatchSorting,
    setRowSelection,
    dispatchGrouping,
  };
};
