import { ChangeEvent, useMemo, useState } from 'react';

import SearchBar from '../../form/components/searchbar/SearchBar';

// Misc
import { debounce } from '@/utils/debounce';

interface SearchProps {
  setGlobalFilter: (filter: string) => void;
}

const Search = ({ setGlobalFilter }: SearchProps) => {
  const [value, setValue] = useState('');
  const eventHandler = (e: ChangeEvent<HTMLInputElement>) => setGlobalFilter(e.target.value);
  const setValueCb = (e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value);
  const onChange = useMemo(() => debounce(eventHandler, setValueCb, 500), []);

  return (
    <SearchBar
      name="table_search_box"
      placeholder="Search"
      onChange={onChange}
      search={value}
    />
  );
};

export default Search;
