import { getMappedIconsFromImport } from '../utils/getIcons';

const industryIconsImport = (require as any).context('@/assets/icons/industry', true, /\.svg$/);

const industryIcons = getMappedIconsFromImport(industryIconsImport);

export const INDUSTRY_ICON_MAP = {
  1388: industryIcons.energy,
  1389: industryIcons.energy,
  1390: industryIcons.energy,
  1391: industryIcons.energy,

  1392: industryIcons.industrials,
  1393: industryIcons.industrials,
  1394: industryIcons.industrials,
  1395: industryIcons.industrials,
  1396: industryIcons.industrials,
  1397: industryIcons.industrials,
  1398: industryIcons.industrials,
  1399: industryIcons.industrials,
  1400: industryIcons.industrials,
  1401: industryIcons.industrials,
  1402: industryIcons.industrials,
  1403: industryIcons.industrials,
  1404: industryIcons.industrials,
  1405: industryIcons.industrials,
  1406: industryIcons.industrials,
  1407: industryIcons.industrials,
  1408: industryIcons.industrials,
  1409: industryIcons.industrials,

  1410: industryIcons['consumer-staples'],
  1411: industryIcons['consumer-staples'],
  1412: industryIcons['consumer-staples'],
  1413: industryIcons['consumer-staples'],
  1414: industryIcons['consumer-staples'],
  1415: industryIcons['consumer-staples'],
  1416: industryIcons['consumer-staples'],
  1417: industryIcons['consumer-staples'],
  1418: industryIcons['consumer-staples'],
  1419: industryIcons['consumer-staples'],

  1420: industryIcons.financials,
  1421: industryIcons.financials,
  1422: industryIcons.financials,
  1423: industryIcons.financials,
  1424: industryIcons.financials,
  1425: industryIcons.financials,
  1426: industryIcons.financials,
  1427: industryIcons.financials,
  1428: industryIcons.financials,
  1429: industryIcons.financials,
  1430: industryIcons.financials,
  1431: industryIcons.financials,
  1432: industryIcons.financials,
  1505: industryIcons.financials,

  1433: industryIcons['communication-services'],
  1434: industryIcons['communication-services'],
  1435: industryIcons['communication-services'],
  1436: industryIcons['communication-services'],
  1437: industryIcons['communication-services'],
  1438: industryIcons['communication-services'],
  1439: industryIcons['communication-services'],
  1440: industryIcons['communication-services'],

  1441: industryIcons['real-estate'],
  1442: industryIcons['real-estate'],
  1443: industryIcons['real-estate'],
  1444: industryIcons['real-estate'],

  1445: industryIcons.government,
  1446: industryIcons.government,
  1447: industryIcons.government,
  1448: industryIcons.government,
  1449: industryIcons.government,
  1450: industryIcons.government,
  1451: industryIcons.government,
  1452: industryIcons.government,
  1990: industryIcons.government,
  1991: industryIcons.government,
  1992: industryIcons.government,

  1454: industryIcons['information-technology'],
  1455: industryIcons['information-technology'],
  1456: industryIcons['information-technology'],
  1457: industryIcons['information-technology'],
  1458: industryIcons['information-technology'],
  1459: industryIcons['information-technology'],
  1460: industryIcons['information-technology'],
  1461: industryIcons['information-technology'],
  1462: industryIcons['information-technology'],
  1463: industryIcons['information-technology'],

  1464: industryIcons['health-care'],
  1465: industryIcons['health-care'],
  1466: industryIcons['health-care'],
  1467: industryIcons['health-care'],
  1468: industryIcons['health-care'],
  1469: industryIcons['health-care'],
  1470: industryIcons['health-care'],
  1471: industryIcons['health-care'],
  1472: industryIcons['health-care'],

  1473: industryIcons.utilities,
  1474: industryIcons.utilities,
  1475: industryIcons.utilities,
  1476: industryIcons.utilities,
  1477: industryIcons.utilities,
  1478: industryIcons.utilities,
  1479: industryIcons.utilities,

  1480: industryIcons['consumer-discretionary'],
  1481: industryIcons['consumer-discretionary'],
  1482: industryIcons['consumer-discretionary'],
  1483: industryIcons['consumer-discretionary'],
  1484: industryIcons['consumer-discretionary'],
  1485: industryIcons['consumer-discretionary'],
  1486: industryIcons['consumer-discretionary'],
  1487: industryIcons['consumer-discretionary'],
  1488: industryIcons['consumer-discretionary'],
  1489: industryIcons['consumer-discretionary'],
  1490: industryIcons['consumer-discretionary'],
  1491: industryIcons['consumer-discretionary'],
  1492: industryIcons['consumer-discretionary'],
  1493: industryIcons['consumer-discretionary'],
  1494: industryIcons['consumer-discretionary'],
  1495: industryIcons['consumer-discretionary'],
  35896: industryIcons['consumer-discretionary'],

  1496: industryIcons.materials,
  1497: industryIcons.materials,
  1498: industryIcons.materials,
  1499: industryIcons.materials,
  1500: industryIcons.materials,
  1501: industryIcons.materials,
  1502: industryIcons.materials,
};
