import { superstructResolver } from '@hookform/resolvers/superstruct';

// Components
import Form from '@/components/content/form/Form';
import DefaultOption from '@/components/content/form/DefaultOption';
import File from '@/components/content/form/components/file/File';
import Input from '@/components/content/form/components/Input';
import Select from '@/components/content/form/components/Select';
import TextArea from '@/components/content/form/components/TextArea';
import InputDisplayControl from '@/components/content/form/components/InputDisplayControl';

// Hooks
import { useSupportForm } from './useSupportForm';
import { useAuth } from '@/hooks/useAuth';
import { getEntries, objectHasValues } from '@/utils';

// Types
import { type SupportFormFields, SupportFormLabels } from './types';
import type { FetchError } from '@/types/api';

interface FormProps {
  prefillData?: Partial<SupportFormFields>;
  attachment?: boolean;
  subjects: Array<string>;
  name: string;
  disabledFields?: { [key in keyof SupportFormFields]?: boolean };
  hideFields?: { [key in keyof SupportFormFields]?: boolean };
  onSuccess?: VoidFunction;
  onError?: (error: FetchError) => void;
  triggerOtherOn?: string;
}

const SupportForm = ({
  prefillData,
  attachment,
  disabledFields,
  subjects,
  hideFields,
  onSuccess,
  onError,
  name,
  triggerOtherOn,
}: FormProps) => {
  const { onSubmit, isPostingRfi, schema } = useSupportForm({ onSuccess, onError });
  const { userInfo: { subclients } } = useAuth();
  const hasSubclients = objectHasValues(subclients);

  return (
    <Form<SupportFormFields>
      onSubmit={onSubmit}
      options={{ resolver: superstructResolver(schema), defaultValues: prefillData }}
      submitButton={{
        text: 'Submit',
        disabled: isPostingRfi,
      }}
      name={name}
      fields={SupportFormLabels}
    >
      {({ register, watch, formState: { errors }, resetField }) => (
        <>
          {!hideFields?.subclient_id && hasSubclients && (
            <Select<SupportFormFields>
              register={register}
              name="subclient_id"
              options={getEntries(subclients).map(([value, name]) => ({ value, name }))}
              Option={DefaultOption}
              placeholder="Select Subclient"
              // eslint-disable-next-line max-len
              info="Select a subclient to send the request in their behalf. If you do not select a subclient, the request will be sent as yourself"
              label={SupportFormLabels.subclient}
              error={errors?.subclient_id}
              disabled={disabledFields?.subclient_id}
            />
          )}

          {!hideFields?.subject && (
            <Select<SupportFormFields>
              register={register}
              name="subject"
              options={subjects.map((subject) => ({ name: subject, value: subject }))}
              Option={DefaultOption}
              label={SupportFormLabels.subject}
              error={errors?.subject}
              disabled={disabledFields?.subject}
              required
            />
          )}

          {triggerOtherOn && !hideFields?.otherSubject && (
            <InputDisplayControl<SupportFormFields> watch={watch} watchFieldName="subject" watchValue={triggerOtherOn}>
              <Input<SupportFormFields>
                register={register}
                label={SupportFormLabels.otherSubject}
                type="text"
                name="otherSubject"
                error={errors?.otherSubject}
                placeholder="Write a subject"
                disabled={disabledFields?.otherSubject}
                required
              />
            </InputDisplayControl>
          )}

          <Input<SupportFormFields>
            register={register}
            label={SupportFormLabels.dueDate}
            type="date"
            name="due_date"
            error={errors?.due_date}
            required
            hidden={hideFields?.due_date}
          />

          {!hideFields?.description && (
            <TextArea<SupportFormFields>
              register={register}
              name="description"
              label={SupportFormLabels.description}
              error={errors?.description}
              required
            />
          )}

          {attachment && (
            <File<SupportFormFields>
              register={register}
              name="attachment"
              label={SupportFormLabels.attachment}
              watch={watch}
              resetField={resetField}
            />
          )}

          <Input
            register={register}
            type="hidden"
            name="to"
            hidden
          />

          <Input
            register={register}
            type="hidden"
            name="ticket_id"
            hidden
          />
        </>
      )}
    </Form>
  );
};

export default SupportForm;
