import { Box, Group, Stack } from '@mantine/core';

import Button from '@/components/content/button/Button';

import LogoIcon from '@/assets/icons/logo.svg';
import LogoNameIcon from '@/assets/icons/logo-name.svg';

import classes from './Timeout.module.css';

const Timeout = () => {
  const redirectLogin = () => {
    window.location.replace('/login');
  };

  return (
    <Stack align="center" justify="center" gap={50} classNames={{ root: classes.wrapper }}>
      <Group align="center" justify="center" gap={40} wrap="nowrap">
        <Box component={LogoIcon} w={100} />
        <Box component={LogoNameIcon} w="100%" />
      </Group>

      <div className={classes.text}>
        <p>
          Your session expired due to inactivity. Please login again to keep using Mercury features.
        </p>

        <Button onClick={redirectLogin} fullWidth>
          Login Again
        </Button>
      </div>

    </Stack>
  );
};

export default Timeout;
