import { useEffect, useMemo, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Box, Group, LoadingOverlay, NativeSelect } from '@mantine/core';
import { getCoreRowModel, useReactTable, getExpandedRowModel } from '@tanstack/react-table';

// Components
import Head from './Head';
import Body from './Body';
import BodyWithMessage from './BodyWithMessage';
import Toolbar from './toolbar/Toolbar';
import EntriesPagination from '../pagination/EntriesPagination';

// Types
import type { TableInstanceProps } from '@/types/table';

import classes from './Table.module.css';

const TableInstance = <TTableData extends unknown>({
  instanceConfiguration,
  data,
  extraData,
  pageCount,
  id,
  isError = false,
  isLoading = false,
  errorMessage = 'There was an error loading the data',
  noContentMessage = 'No data available',
  subRowComponent,
  customActions,
  options,
  entries,
  onRowSelection,
  renderSelection,
  groupBy,
  csv,
}: TableInstanceProps<TTableData>) => {
  const selectionRef = useRef<HTMLDivElement>(null);
  const pagination = instanceConfiguration?.state?.pagination;
  const pageSize = pagination?.pageSize || 0;
  const { hideSearch, hideToolbar, hidePageSize, noHighlight } = options || {};

  const includePageCount = useMemo(() => (
    pageCount ? { pageCount: Math.ceil(pageCount / pageSize) } : {}
  ), [pageCount, pageSize]);

  const instance = useReactTable<TTableData>({
    data,
    ...instanceConfiguration,
    ...includePageCount,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    enableSortingRemoval: false,
    getRowId: (row: any) => (row.issue_id ? row.issue_id : row.id),
    globalFilterFn: (row, columnId, filterValue: string) => {
      const search = (typeof filterValue === 'number' ? String(filterValue) : filterValue).toLowerCase();
      let value = row.getValue(columnId) as string;

      if (typeof value === 'number') value = String(value);

      return value?.toLowerCase().includes(search);
    },
  });

  useEffect(() => {
    if (onRowSelection) {
      onRowSelection(
        instance.getSelectedRowModel().flatRows.map((row) => row.original),
        instance.getState().rowSelection,
      );
    }
  }, [Object.values(instance.getState().rowSelection).length]);

  const hasPages = instance.getRowModel().rows.length > 0;
  const message = isError ? errorMessage : noContentMessage;
  const color = isError ? 'power' : 'char';
  const selectedItems = Object.keys(instance.getState().rowSelection).length;

  return (
    <div className={`${classes.tableWrapper} ${noHighlight ? 'table__wrapper--nohighlight' : ''}`}>
      <div className={classes.tableMain}>
        {!hideToolbar && (
          <Toolbar
            tableInstance={instance}
            hideSearch={hideSearch}
            customActions={customActions}
          />
        )}

        {(groupBy || renderSelection || csv) && (
          <Group justify="flex-end">
            {groupBy && (
              <NativeSelect
                value={instance?.getState().grouping[0]}
                aria-label="Group By"
                onChange={(e) => { instance?.setGrouping(() => [e.target.value]); }}
                data={groupBy as Array<string>}
                size="xs"
              />
            )}

            {/* Selection */}
            <CSSTransition
              in={selectedItems > 0}
              nodeRef={selectionRef}
              timeout={500}
              className={classes.selectionWrapper}
              classNames={{
                enter: classes.enter,
                enterActive: classes.enterActive,
                exit: classes.exit,
                exitActive: classes.exitActive,
              }}
              unmountOnExit
            >
              <Box ref={selectionRef} mr="auto">
                {renderSelection && renderSelection({ nItems: selectedItems })}
              </Box>
            </CSSTransition>

            {csv && hasPages && (<Box ml="auto">{csv}</Box>)}
          </Group>
        )}

        <div className={classes.tableOverflow}>
          <table className={classes.table} id={id}>
            <Head tableInstance={instance} />

            {hasPages && (
              <Body
                noHighlight={noHighlight}
                instance={instance}
                extraData={extraData}
                subRowComponent={subRowComponent}
              />
            )}

            {(isError || !hasPages) && (
              <BodyWithMessage message={message} color={color} span={instance.getVisibleFlatColumns().length} />
            )}
          </table>
        </div>

        {!options?.hidePagination && (
          <EntriesPagination
            next={instance.nextPage}
            previous={instance.previousPage}
            onEntriesChange={(size) => instance.setPageSize(size)}
            canNext={instance.getCanNextPage()}
            canPrevious={instance.getCanPreviousPage()}
            pageCount={instance.getPageCount()}
            size={instance.getState().pagination.pageSize}
            page={instance.getState().pagination.pageIndex + 1}
            hidePageSize={hidePageSize}
            entries={entries}
          />
        )}

        <LoadingOverlay
          visible={isLoading}
          zIndex={99}
          overlayProps={{ radius: 'sm', blur: 1 }}
          loaderProps={{ size: 'xl' }}
        />
      </div>

    </div>
  );
};

export default TableInstance;
