import { Flex } from '@mantine/core';

import Pill from './Pill';
import { maxOutArray } from '@/utils';

import type { PillsBaseProps } from './types';

type PillsArrayProps = {
  pills: Array<any>;
  max?: number;
  customKeys?: Array<string>;
} & PillsBaseProps;

const PillsList = ({
  pills = [],
  max,
  pillProps,
  color,
  size,
  customKeys,
}: PillsArrayProps) => {
  if (pills.length <= 0) return null;

  const renderPill = maxOutArray(pills, max);

  return (
    <Flex wrap="wrap" align="center" gap={7} component="ul">
      {renderPill.map((pill, index) => (
        <Pill
          key={customKeys ? customKeys[index] : pill}
          value={pill}
          pillProps={pillProps}
          color={color}
          size={size}
        />
      ))}
    </Flex>
  );
};

export default PillsList;
