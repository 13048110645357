import produce from 'immer';
import { useMutationState, useQuery, useQueryClient } from '@tanstack/react-query';

import { fetchData } from '@/lib/fetch';
import { authKeys } from '@/features/auth/services/keys';

import type { UserInfo } from '@/features/auth/types';

export const useAuth = (enabled?: boolean) => {
  const queryClient = useQueryClient();
  const [logout] = useMutationState({
    filters: { mutationKey: ['logout'] },
    select: (state) => state.state,
  });

  const { data: userInfo, isSuccess, isLoading, isPending, isError } = useQuery<UserInfo>({
    queryKey: authKeys.userinfo(),
    queryFn: () => fetchData({
      endpoint: 'userinfo',
      error: 'Couldn\'t verify user',
    }),
    enabled: logout?.status === 'pending' ? false : enabled,
    meta: {
      avoidAuthRedirection: true,
    },
  });

  const setUserInfo = (data: UserInfo) => {
    queryClient.setQueryData(authKeys.userinfo(), data);
  };

  const modifyUserInfo = (newData: Partial<UserInfo> = {}) => {
    queryClient.setQueryData<Partial<UserInfo>>(authKeys.userinfo(), (oldData) => produce(oldData, (draftState) => ({
      ...draftState,
      ...newData,
    })));
  };

  const getContextData = (context: 'relevant_industry_sectors' | 'relevant_technologies' | 'relevant_locations') => {
    const contextData = (userInfo as UserInfo)[context] || [];

    const result: { ids: Array<number>; names: Array<string> } = { ids: [], names: [] };

    const getFlat = (node: typeof contextData[number]) => {
      result.ids.push(node.id);
      result.names.push(node.name);

      if (node.children) {
        node.children.forEach((child) => getFlat(child));
      }

      if (node.parents) {
        node.parents.forEach((parent) => getFlat(parent));
      }
    };

    contextData.forEach((item) => getFlat(item));

    return result;
  };

  return {
    setUserInfo,
    userInfo: userInfo as UserInfo,
    modifyUserInfo,
    getContextData,
    isLoading,
    isPending,
    isError,
    isSuccess,
  };
};
