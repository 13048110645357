import { useContext } from 'react';

import { MercuryTableContext, MercuryTableContextValue } from '../MercuryTableContext';

export const useMercuryTable = <T extends unknown>() => {
  const mercuryTableContext = useContext<MercuryTableContextValue<T> | null>(MercuryTableContext);

  if (!mercuryTableContext) {
    throw new Error('MercuryTableContext is not initialized. This hook must be used inside a MercuryTableProvider');
  }

  return mercuryTableContext;
};
