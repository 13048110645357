import { useMemo } from 'react';
import ReactDOM from 'react-dom';

import { Toast } from './Toast';

import { useToast } from '@/hooks/useToast';

import classes from './Toast.module.css';

export const ToastContainer = () => {
  const toastsContainerRef = useMemo(() => document.querySelector('#toasts')! as HTMLElement, []);

  const { toasts } = useToast();

  return ReactDOM.createPortal(
    <div className={classes.container}>
      {toasts.map((toast) => (
        <Toast key={toast.id} {...toast} />
      ))}
    </div>,
    toastsContainerRef,
  );
};
