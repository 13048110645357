import { useEffect } from 'react';

// Components
import RouterLink from '../link/RouterLink';

// Types
import type { MessageConfig, MessageType } from '../../../hooks/useMessage';

// Misc
import { sanitize } from '@/utils/sanitize';
import { Box, TypographyStylesProvider } from '@mantine/core';

import classes from './Message.module.css';

interface MessageProps extends MessageConfig {
  message?: MessageType | null;
  removeMessage?: VoidFunction;
}

const Message = ({ message, removeMessage, closeButton, customHtml, timeout, link }: MessageProps) => {
  useEffect(() => {
    let messageTimeout: ReturnType<typeof setTimeout>;

    if (message && timeout) {
      messageTimeout = setTimeout(() => {
        if (removeMessage) removeMessage();
      }, timeout);
    }

    return () => clearTimeout(messageTimeout);
  }, [message]);

  if (!message) {
    return null;
  }

  const colorVariable = (type: string, prop: 'bg' | 'font') => `var(--${type}-message-${prop})`;

  return (
    <Box
      className={classes.wrapper}
      style={{
        '--wrapper-message-bg': colorVariable(message.type, 'bg'),
      }}
    >

      <Box
        className={classes.text}
        style={{
          '--message-font-color': colorVariable(message.type, 'font'),
        }}
      >
        {customHtml ? (
          <div>
            <TypographyStylesProvider dangerouslySetInnerHTML={{ __html: sanitize(message.text) }} />
          </div>
        ) : (
          <p>{message.text}</p>
        )}
        {closeButton && <button type="button" onClick={removeMessage} aria-label="Close Message">×</button>}
        {link && <RouterLink to={link.to}>{link.text}</RouterLink>}
      </Box>
    </Box>
  );
};

export default Message;
