/* eslint-disable react/jsx-props-no-spreading */
import { flexRender, HeadersInstance } from '@tanstack/react-table';

// Styles & Assets
import ChevronUp from '@/assets/icons/content/chevron-up.svg';
import Help from '@/assets/icons/content/info.svg';
import Tooltip from '../tooltip/Tooltip';

import classes from './Table.module.css';

const Head = ({ tableInstance }: { tableInstance: HeadersInstance<any> }) => (
  <thead>
    {tableInstance.getHeaderGroups().map((headerGroup) => (
      <tr className={classes.tr} key={headerGroup.id}>
        {headerGroup.headers.map((header) => {
          const { column } = header;
          const { alignment, noSize } = column.columnDef.meta || {};

          return (
            <th
              scope="col"
              key={header.id}
              onClick={column.getToggleSortingHandler()}
              style={{ width: `${noSize ? '' : column.getSize()}px` }}
              className={`
                ${classes.tableElement}
                ${alignment === 'numeric' ? classes.numeric : ''}
                ${alignment === 'center' ? classes.center : ''}
              `}
            >
              <span
                className={`
                    ${classes.thContent}
                    ${column.getIsSorted() === 'desc' ? classes.contentDesc : ''}
                    ${column.getIsSorted() ? classes.contentSorted : ''}
            `}
              >

                {flexRender(column.columnDef.header, header.getContext())}

                {column.getCanSort() && (<ChevronUp />)}

                {column.columnDef.meta?.columnInfo && (
                  <Tooltip title={column.columnDef.meta.columnInfo}>
                    <span>
                      <Help />
                    </span>
                  </Tooltip>
                )}
              </span>
            </th>
          );
        })}
      </tr>
    ))}
  </thead>
);
export default Head;
