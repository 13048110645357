import {
  ActionIcon, Loader, Text, TypographyStylesProvider, createTheme, rem,
} from '@mantine/core';

import actionIconClasses from '../styles/globals/ActionIcon.module.css';

// Classes
import typographyClasses from '../styles/globals/Typography.module.css';
import textClasses from '../styles/globals/Text.module.css';

const breakpoints = {
  xs: '36em',
  s: '48em',
  sm: '47.9375em',
  m: '62em',
  mm: '61.9375em',
  lg: '80em',
  lm: '79.9375em',
  xl: '90em',
};

const fontFamily = 'Glacial';

const headings = {
  fontFamily,
  sizes: {
    h1: {
      fontSize: rem(34),
      fontWeight: '100',
      lineHeight: '1.5',
    },
    h2: {
      fontSize: rem(24),
      fontWeight: '100',
      lineHeight: '1.5',
    },
    h3: {
      fontSize: rem(22),
      fontWeight: '100',
      lineHeight: '1.5',
    },
    h4: {
      fontSize: rem(18),
      fontWeight: '600',
      lineHeight: '1.5',
    },
    h5: {
      fontSize: rem(16),
      fontWeight: '600',
      lineHeight: '1.5',
    },
  },
};

export const mantineTheme = createTheme({
  breakpoints,
  fontFamily,
  headings,
  components: {
    Loader: Loader.extend({
      vars: (theme, props) => ({
        root: {
          '--loader-color': props.color ? props.color : 'var(--quo-blue-75)',
        },
      }),
    }),
    Text: Text.extend({
      classNames: textClasses,
    }),
    ActionIcon: ActionIcon.extend({
      classNames: actionIconClasses,
      vars: (theme, props) => ({
        root: {
          '--ai-color': props.color ? props.color : 'var(--svg-icon-color)',
        },
      }),
    }),
    TypographyStylesProvider: TypographyStylesProvider.extend({
      classNames: {
        root: typographyClasses.root,
      },
    }),
  },
});
