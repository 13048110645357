import { Box } from '@mantine/core';
import classes from './Table.module.css';

// Types
interface MessagesProps {
  message: string;
  color: string;
  span: number;
}

const BodyWithMessage = ({ message, color, span }: MessagesProps) => (
  <tbody>
    <tr>
      <Box
        component="td"
        className={classes.messageTd}
        colSpan={span}
        style={{
          '--table-message-color': `var(--quo-${color}-50)`,
        }}
      >
        {message}
      </Box>
    </tr>
  </tbody>
);

export default BodyWithMessage;
