import { Group } from '@mantine/core';

import IconWithLabel from './IconWithLabel';

import World from '@/assets/icons/regions/world.svg';
import { getEntries, maxOutArray } from '@/utils';

interface LocationIconProps {
  locations: Record<number, string>;
  id: any;
  max?: number;
  layout?: 'small';
}

const regionName: Record<string, { long: string, short: string }> = {
  368: { long: 'Africa', short: 'AF' },
  383: { long: 'Asia', short: 'AS' },
  384: { long: 'Europe', short: 'EUR' },
  385: { long: 'Middle East', short: 'MENA' },
  465: { long: 'South America', short: 'SA' },
  466: { long: 'Central America', short: 'CA' },
  815: { long: 'North America', short: 'NOAM' },
  1518: { long: 'Oceania', short: 'OCE' },
};

const LocationIcon = ({
  locations,
  id,
  max,
  layout,
}: LocationIconProps) => {
  const icons = maxOutArray(
    getEntries(locations).map(([locationId, location]) => (
      regionName[locationId] ?? { long: location, short: location })),
    max,
    (text) => ({ long: text, short: text }),
  ) as Array<{ long: string, short: string }>;

  return (
    <Group gap={10} align="center" justify="center">
      {icons.map((label) => (
        <IconWithLabel
          key={`${id}-${label.short}`}
          icon={<World />}
          label={label.short}
          tooltip={label.long}
          layout={layout}
        />
      ))}
    </Group>
  );
};

export default LocationIcon;
