import { useState } from 'react';

export type MessageConfig = {
  timeout?: number;
  link?: {
    to: string;
    text: string;
  };
  closeButton?: boolean;
  customHtml?: boolean;
};

type MessageOptions = {
  type: 'success' | 'error' | 'warning';

};

export type MessageType = {
  text: string;
} & MessageOptions;

export const useMessage = (initialMessage?: MessageType) => {
  const [message, setMessage] = useState<MessageType | null | undefined>(initialMessage);
  const [messageConfig, setMessageConfig] = useState<MessageConfig>();

  const createMessage = (text: string, options: MessageOptions, config?: MessageConfig) => {
    setMessage({
      text,
      ...options,
    });

    if (config) {
      setMessageConfig(config);
    }
  };

  const removeMessage = () => {
    setMessage(null);
  };

  return { createMessage, removeMessage, message, messageConfig };
};
