import { useMemo, ChangeEvent } from 'react';
import styled from 'styled-components';

import { CheckboxFlexWrapper,
  CheckboxGroupWrapper,
  CheckboxStyled } from '@/components/content/form/components/Checkbox';

import type { FilterProps } from '../../types';
import type { Multiple } from '@/types/meta';

import { getSelected } from './utils';

const CheckboxContainer = styled.div`
  border: 1px solid var(--default-border);
  border-radius: var(--border-radius);
  min-height: 36px;
  padding: 5px 3px 5px 12px;
`;

const Checkbox = ({ filterMeta, filter, setFilter }: FilterProps<Multiple, number[] | number | string>) => {
  const selectedItems = useMemo(() => getSelected({ filter, filterMeta }).map((item) => item.value), [filter]);

  const selectItem = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const { checked } = e.target;
    let newSelectedItems = [...selectedItems];

    if (checked) {
      newSelectedItems.push(value);
    } else {
      newSelectedItems = newSelectedItems.filter((filValue) => filValue !== value);
    }

    setFilter({ [filterMeta.name]: newSelectedItems });
  };

  const isChecked = (value: string | number) => selectedItems.includes(value);

  return (
    <CheckboxContainer>
      <CheckboxGroupWrapper $layout="flex">
        {filterMeta.choices.map((filValue) => {
          const { value } = filValue;

          return (
            <CheckboxFlexWrapper key={filValue.value} color="inherit">
              <CheckboxStyled
                type="checkbox"
                onChange={selectItem}
                value={value}
                name={filterMeta.name}
                checked={isChecked(value)}
              />
              {filValue.label}
            </CheckboxFlexWrapper>
          );
        })}
      </CheckboxGroupWrapper>
    </CheckboxContainer>
  );
};

export default Checkbox;
