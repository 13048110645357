import type { ReactNode } from 'react';
import { Group, GroupProps } from '@mantine/core';

import Tooltip from '@/components/content/tooltip/Tooltip';

import classes from './IconWithLabel.module.css';

interface IconWithLabelProps extends GroupProps {
  icon: ReactNode;
  label: string;
  tooltip?: string;
  hideIcon?: boolean;
  layout?: 'small'
}

const IconWithLabel = ({
  icon,
  label,
  tooltip,
  hideIcon,
  layout,
  ...groupProps
}: IconWithLabelProps) => (
  <Group align="center" justify="center" gap={15} {...groupProps}>
    <Tooltip title={tooltip} disabled={!tooltip}>
      <span className={`${classes.wrapper} ${layout === 'small' ? classes.small : ''}`}>
        {!hideIcon && icon}
        {label}
      </span>
    </Tooltip>
  </Group>
);

export default IconWithLabel;
