import type { ChangeEvent } from 'react';
import styled from 'styled-components';

import { CheckboxStyled } from '../../form/components/Checkbox';

import type { FilterProps } from '../types';
import type { Single } from '@/types/meta';

const SingleStyled = styled.div`
  border: 1px solid var(--default-border);
  border-radius: var(--border-radius);
  min-height: 36px;
  padding: 5px 3px 5px 12px;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  font-size: 14px;
`;

const SingleFilter = ({ setFilter, filter, filterMeta }: FilterProps<Single, string>) => {
  const selectItem = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;

    setFilter({ [filterMeta.name]: checked });
  };

  return (
    <SingleStyled>
      <Label>
        <CheckboxStyled
          type="checkbox"
          onChange={selectItem}
          value={filter}
          name={filterMeta.name}
          checked={filter === 'true'}
        />

        {filterMeta.description}
      </Label>
    </SingleStyled>
  );
};

export default SingleFilter;
