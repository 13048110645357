import { useAuth } from '@/hooks/useAuth';

import type { NormalizedRedmineField } from '../catalogs/types';

interface RiskIndicatorsProps {
  context: NormalizedRedmineField;
  indicator: 'relevant_technologies' | 'relevant_locations' | 'relevant_industry_sectors';
}

export const useRiskIndicator = ({
  context,
  indicator,
}: RiskIndicatorsProps) => {
  const auth = useAuth();
  const userContext = auth.userInfo[indicator];

  function getRelevantContext() {
    const searchInChildren = (children: typeof userContext | null, contextId: number) => {
      if (!children) return false;

      return children.some((child) => +child.id === contextId);
    };

    return userContext
      .filter((userContextItem) => (
        context.some((contextItem) => (
          +contextItem.id === +userContextItem.id
          || searchInChildren(userContextItem.children, +contextItem.id)
          || searchInChildren(userContextItem.parents, +contextItem.id)
        ))
      ))
      .map((userContextItem) => userContextItem.name);
  }

  const relevantContext: string[] = getRelevantContext();

  return {
    hasIndicator: relevantContext.length > 0,
    relevantContext,
  };
};
