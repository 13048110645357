import { Title, TitleProps, createPolymorphicComponent } from '@mantine/core';

import classes from './Title.module.css';

const MercuryTitle = createPolymorphicComponent<'h1', TitleProps>(
  ({ children, ...titleProps }: TitleProps) => (
    <Title {...titleProps} classNames={{ root: classes.title }}>
      {children}
    </Title>
  ),
);

export default MercuryTitle;
