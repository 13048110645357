import { forwardRef } from 'react';
import { Button, ButtonProps, createPolymorphicComponent } from '@mantine/core';

import classes from './Button.module.css';

// Default root element is 'button', but it can be changed with 'component' prop
const MercuryButton = createPolymorphicComponent<'button', ButtonProps>(
  forwardRef<HTMLButtonElement, ButtonProps>(({ children, classNames, ...buttonProps }, ref) => (
    <Button ref={ref} classNames={{ root: classes.root, ...classNames ?? {} }} {...buttonProps}>
      {children}
    </Button>
  )),
);

export default MercuryButton;
