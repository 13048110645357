/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-nested-ternary */
import { memo, ReactNode } from 'react';
import { flexRender, Row } from '@tanstack/react-table';

// Types
import type { ExtraDataObj } from '@/types/mercury-data-types';

// Misc
import { isRead } from '@/utils/notifications';

import classes from './Table.module.css';

interface RowProps {
  isExpanded: boolean;
  isSelected: boolean;
  renderRowSubComponent: (row: Row<any>) => ReactNode;
  row: Row<any>;
  readonly extraData?: ExtraDataObj;
  noHighlight?: boolean;
}

const RowComp = memo(
  ({ row, isExpanded, noHighlight, renderRowSubComponent, extraData = {} }: RowProps) => {
    const subRowComponent = renderRowSubComponent(row);

    return (
      <>
        <tr className={`
            ${classes.tr}
            ${noHighlight
            || isRead(extraData, row.original.issue_id || row.original.id) ? '' : classes.highlighted
          }
          `}
        >
          {row.getVisibleCells().map((cell) => {
            const { column } = cell;
            const { alignment } = column.columnDef.meta || {};

            return (
              <td
                key={cell.id}
                className={`
                  ${classes.tableElement}
                  ${alignment === 'numeric' ? classes.numeric : ''}
                  ${alignment === 'center' ? classes.center : ''}
                `}
              >
                {
                  cell.getIsAggregated() ? (
                    // If the cell is aggregated, use the Aggregated
                    // renderer for cell
                    flexRender(
                      cell.column.columnDef.aggregatedCell
                      ?? cell.column.columnDef.cell,
                      cell.getContext(),
                    )
                  ) : cell.getIsPlaceholder() ? null : ( // For cells with repeated values, render null
                    // Otherwise, just render the regular cell
                    flexRender(
                      cell.column.columnDef.cell,
                      cell.getContext(),
                    )
                  )
                }
              </td>
            );
          })}
        </tr>

        {isExpanded && subRowComponent ? (
          <tr className={classes.tr}>
            <td colSpan={row.getVisibleCells().length}>
              {subRowComponent}
            </td>
          </tr>
        ) : null}
      </>
    );
  },
);

export default RowComp;
