import type { ReactNode } from 'react';
import { Group } from '@mantine/core';

import Linkv2 from '../link/Linkv2';

// Types
import type { Toast as ToastType, ToastLevel } from './types';

// Styles & Icons
import CloseIcon from '@/assets/icons/content/close.svg';
import SuccessIcon from '@/assets/icons/content/success.svg';
import WarningIcon from '@/assets/icons/content/warning.svg';
import ErrorIcon from '@/assets/icons/content/error.svg';

import classes from './Toast.module.css';

type ToastProps = ToastType;

const levelLabel: Record<ToastLevel, string> = {
  success: 'Success',
  warning: 'Warning',
  error: 'Error',
};

const levelIcons: Record<ToastLevel, ReactNode> = {
  success: <SuccessIcon />,
  warning: <WarningIcon />,
  error: <ErrorIcon />,
};

export const Toast = ({ message, clear, level, link }: ToastProps) => (
  <button type="button" onClick={clear} className={`${classes.toast} ${classes[level]}`}>
    <Group classNames={{ root: classes.icon }}>
      {levelIcons[level]}
    </Group>

    <output className={classes.output}>
      <span className={classes.label}>{levelLabel[level]}</span>

      <span className={classes.message}>
        {message}
        {link && (
          <div className={classes.link}>
            <Linkv2 href={link} target="_blank">{link}</Linkv2>
          </div>
        )}
      </span>
    </output>

    <CloseIcon className={classes.closeIcon} />
  </button>
);
