import styled, { css } from 'styled-components';
import type { FieldError } from 'react-hook-form';

// Components
import InputWrapper from './InputWrapper';

// Types
import type { Select as SelectProps } from '@/types/forms';
import { Info } from './Input';

const SelectBase = styled.select`
  font-size: 14px;
  color: var(--content-font);
  width: 100%;
  background: none;
  border: 1px solid var(--default-border);
  border-radius: 4px;
  min-height: 36px;
  padding: 5px 3px 5px 12px;

  &:disabled {
    opacity: 0.7;
    background-color: var(--item-bg);
  }
`;

const errorStyle = css`
  border-bottom: 3px solid var(--quo-power-75);
`;

const SelectStyled = styled(SelectBase) <{ $error?: FieldError; }>`
  ${(props) => props.$error && errorStyle}
`;

const Select = <TFormValues extends Record<string, unknown>>({
  options,
  Option,
  label,
  name,
  error,
  register,
  layout,
  formOptions = {},
  required,
  placeholder,
  disabled,
  info,
}: SelectProps<TFormValues>) => (
  <InputWrapper label={{ for: name, text: label, required }} layout={layout}>
    <SelectStyled
      {...(register(name, formOptions))}
      id={name}
      name={name}
      $error={error}
      aria-required={required ? 'true' : 'false'}
      aria-invalid={error ? 'true' : 'false'}
      defaultValue=""
      disabled={disabled}
    >
      {/* Show an optional placeholder as first item in the dropdown */}
      {placeholder && (<option value="" disabled>{placeholder}</option>)}

      {options.map((option) => (
        <Option key={option?.value || option} option={option} />
      ))}
    </SelectStyled>

    {info && <Info id={`addl-${name}`}>{info}</Info>}
  </InputWrapper>
  );

export default Select;
