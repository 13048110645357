import { Fragment, type ReactNode } from 'react';
import { Group, GroupProps } from '@mantine/core';

import Tooltip from '@/components/content/tooltip/Tooltip';

import { getEntries, maxOutArray } from '@/utils';

interface IconMapProps extends GroupProps {
  icons: Record<string, any>;
  map: Record<string, string>;
  tooltip?: (t: string) => string;
  showTooltip?: boolean;
  renderLabelWithoutIcon?: (label: string) => ReactNode;
  layout?: 'small';
  id: any;
  max?: number;
}

const IconMap = ({
  icons,
  map,
  tooltip,
  showTooltip,
  renderLabelWithoutIcon,
  id,
  layout,
  max,
  ...groupProps
}: IconMapProps) => {
  const entries = getEntries(map);

  const createIconsMap = () => {
    const iconMap = new Map();

    entries.forEach(([key, value]) => {
      const icon = icons[key];
      if (iconMap.has(icon)) {
        const current = iconMap.get(icon);

        iconMap.set(icon, `${current}, ${value}`);
      } else {
        iconMap.set(icon, value);
      }
    });

    return iconMap;
  };

  const iconMap = createIconsMap();
  const mapped = Array.from(iconMap);
  const maxed = maxOutArray(mapped, max, (text) => ([null, text]));
  const size = layout === 'small' ? 25 : 30;

  return (
    <Group
      gap={10}
      align="center"
      justify="center"
      style={{ '--svg-icon-color': 'var(--icon-column-stroke)' }}
      {...groupProps}
    >
      {maxed.map(([Icon, label]) => (
        <Fragment key={`${id}-${label}`}>
          {(!Icon && renderLabelWithoutIcon) && (
            renderLabelWithoutIcon(label)
          )}

          {Icon && (
            <Tooltip key={label} title={tooltip ? tooltip(label) : label} disabled={!showTooltip || !label}>
              <Group align="center">
                <Icon width={size} height={size} />
              </Group>
            </Tooltip>
          )}
        </Fragment>
      ))}
    </Group>
  );
};

export default IconMap;
