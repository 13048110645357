import ReactDOM from 'react-dom';

import { usePermanentMessage } from '@/hooks/usePermanentMessage';
import { useMemo } from 'react';

import classes from './PermanentMessage.module.css';

export const PermanentMessage = () => {
  const permanentMessageRef = useMemo(() => document.querySelector('#permanentmessage')! as HTMLElement, []);

  const { message } = usePermanentMessage();

  if (!message) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className={`${classes.message} ${classes[message.level]}`}>
      <span>{message?.message}</span>
    </div>,
    permanentMessageRef,
  );
};
