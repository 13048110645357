import { useContext } from 'react';

import { DrawerContext } from '@/context/DrawerContext';

export const useDrawer = () => {
  const drawerContext = useContext(DrawerContext);

  if (!drawerContext) {
    throw new Error('DrawerContext is not initialized. This hook must be used inside a DrawerContextProvider');
  }

  return drawerContext;
};
