import { type PropsWithChildren, type ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Drawer as MantineDrawer, type DrawerProps } from '@mantine/core';

import classes from './Drawer.module.css';

interface MercuryDrawer extends PropsWithChildren, DrawerProps {
  title?: string;
  link?: ReactNode;
}

const Drawer = ({
  title,
  link,
  opened,
  children,
  position = 'right',
  ...drawerProps
}: MercuryDrawer) => {
  const location = useLocation();

  useEffect(() => {
    if (opened) {
      drawerProps.onClose();
    }
  }, [location]);

  return (
    <MantineDrawer.Root
      opened={opened}
      lockScroll={false}
      radius={4}
      offset={15}
      position={position}
      {...drawerProps}
      classNames={{
        content: classes.content,
        inner: classes.inner,
        title: classes.title,
        header: `${link ? classes.headerWithLink : ''}`,
      }}
    >
      <MantineDrawer.Content>
        <MantineDrawer.Header>
          <div className={`${link ? classes.titleWrapper : ''}`}>
            <MantineDrawer.Title>{title}</MantineDrawer.Title>
            {link && link}
          </div>
          <MantineDrawer.CloseButton aria-label="Close Drawer" />
        </MantineDrawer.Header>
        <MantineDrawer.Body>
          {children}
        </MantineDrawer.Body>
      </MantineDrawer.Content>
    </MantineDrawer.Root>
  );
};

export default Drawer;
