import { Suspense } from 'react';
import {
  Burger, AppShell, Group, ScrollArea, Box,
} from '@mantine/core';
import { Outlet } from 'react-router-dom';
import { useClickOutside, useDisclosure, useWindowScroll } from '@mantine/hooks';

import Loading from '../content/loading/Loading';
import TopBar from './nav/topbar/Topbar';
import Expand from './nav/navbar/Expand';
import InitialModals from './modals/InitialModals';
import HeaderDisclaimer from './nav/topbar/HeaderDisclaimer';

import { mapRoutesToMenu } from './nav/navbar/mapRoutesToMenu';
import { isPreprod } from '@/utils';

import type { LayoutProps } from '@/types/general';

import LogoIcon from '@/assets/icons/logo.svg';
import LogoNameIcon from '@/assets/icons/logo-name.svg';

import layoutClasses from './Layout.module.css';

const DISPLAY_DISCLAIMER = isPreprod;

const Layout = ({ routes, topbarItems, includeSearch, diplayInitialModals }: LayoutProps) => {
  const [mobileOpened, { toggle: toggleMobile, close: closeMobile }] = useDisclosure();
  const [desktopOpened, { toggle: toggleDesktop }] = useDisclosure(false);
  const [scroll] = useWindowScroll();
  const ref = useClickOutside(closeMobile);

  const isScrolled = scroll.y > 50;

  return (
    <>
      <AppShell
        layout="alt"
        header={{ height: DISPLAY_DISCLAIMER ? 95 : 60, offset: true }}
        navbar={{
          width: desktopOpened ? 265 : 70,
          breakpoint: 'sm',
          collapsed: { mobile: !mobileOpened, desktop: !desktopOpened },
        }}
        withBorder={false}
        transitionDuration={300}
        transitionTimingFunction="cubic-bezier(0.44, 0.8, 0.71, 0.98)"
        classNames={{
          root: layoutClasses.root,
          navbar: layoutClasses.navbar,
          header: `${layoutClasses.header} ${isScrolled ? layoutClasses.scrolled : ''}`,
        }}
      >
        <AppShell.Header>
          {DISPLAY_DISCLAIMER && <HeaderDisclaimer />}

          <Group h={60} px="md" justify="space-between">
            <Burger opened={mobileOpened} onClick={toggleMobile} hiddenFrom="sm" size="sm" />
            <TopBar topbarItems={topbarItems} scrolled={isScrolled} includeSearch={includeSearch} />
          </Group>
        </AppShell.Header>
        <AppShell.Navbar p="md" ref={ref}>
          <Group>
            <Burger opened={mobileOpened} onClick={toggleMobile} hiddenFrom="sm" size="sm" />
          </Group>

          <AppShell.Section>
            <Group gap={0} align="center" wrap="nowrap">
              <LogoIcon className={layoutClasses.logo} />
              <LogoNameIcon className={`${layoutClasses.logoName} ${desktopOpened ? layoutClasses.expanded : ''}`} />
            </Group>
          </AppShell.Section>

          <AppShell.Section grow my="md" component={ScrollArea} scrollbars="y">
            {mapRoutesToMenu({ routes, sideBarExpanded: mobileOpened || desktopOpened })}
          </AppShell.Section>

          <AppShell.Section visibleFrom="sm">
            <Expand toggleDesktop={toggleDesktop} desktopOpened={desktopOpened} />
          </AppShell.Section>

        </AppShell.Navbar>
        <AppShell.Main>
          <Suspense fallback={(
            <Loading
              text="Loading assets"
              height="calc(100vh - calc(var(--app-shell-header-offset, 0rem) + var(--app-shell-padding)))"
            />
          )}
          >
            <Box pt={15}>
              <Outlet />
            </Box>
          </Suspense>
        </AppShell.Main>
      </AppShell>

      {diplayInitialModals && <InitialModals />}
    </>
  );
};

export default Layout;
