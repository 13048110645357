// Components
import Child from './Child';

export const mapRouteswithChildren = ({
  child,
  sideBarExpanded,
  showTooltip,
  parent = '',
}: any) => {
  if (!child.nav && !child.children) {
    return undefined;
  }

  if (child.children) {
    const path = `${parent}${(child.path && !child.layout) ? `/${child.path}` : ''}`;

    return child.children.map((deepChild: any) => mapRouteswithChildren({
      child: deepChild,
      sideBarExpanded,
      showTooltip,
      parent: path,
    }));
  }

  return (
    <Child
      key={child.page || child.title}
      child={child}
      path={!parent ? child.path : `${parent}${child.path ? `/${child.path}` : ''}`}
      sideBarExpanded={sideBarExpanded}
      showTooltip={showTooltip}
    />
  );
};
