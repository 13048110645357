import React, { PropsWithChildren } from 'react';
import type { FieldValues, Path, UseFormWatch } from 'react-hook-form/dist/types';

interface InputDisplayControlProps<TFormValues extends FieldValues> {
  watch: UseFormWatch<TFormValues>;
  watchFieldName: Path<TFormValues>;
  watchValue: string | boolean;
}

const InputDisplayControl = <TFormValues extends Record<string, unknown>>({
  children,
  watch,
  watchValue,
  watchFieldName,
}: PropsWithChildren<InputDisplayControlProps<TFormValues>>) => {
  const value = watch ? watch(watchFieldName) : false;
  const display = value === watchValue;

  return (
    <>
      {display ? children : null}
    </>
  );
};

export default InputDisplayControl;
