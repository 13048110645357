import { useMemo } from 'react';

function useValueMap<K extends keyof any, V>(
  value: K,
  map?: Record<K, V>,
  defaultValue?: V,
): V {
  if (!map) return defaultValue as V;

  const mappedValue = useMemo(() => {
    if (value in map) {
      return map[value];
    }
    return defaultValue as V;
  }, [value, map, defaultValue]);

  return mappedValue;
}

export default useValueMap;
